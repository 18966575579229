.policies__main {
    padding-top: 120px;
}

.policies__main__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.policies__main__title {
    font-weight: 800;
    font-size: 3.375rem;
    line-height: 68px;
}

.policies__main__title__decoration {
    width: 209px;
    height: 11px;
    margin-bottom: 36px;
}

.policies__main__text {
    width: 100%;
    max-width: 816px;
    font-size: 1.125rem;
    line-height: 26px;
    margin-bottom: 90px;
}

.policies__main__text p {
    margin-bottom: 12px;
}

.policies__main__text ul {
    padding-left: 20px;
    margin-bottom: 12px;
}

.policies__main__text a {
   color: var(--color-orange);
}

.policies__main__text__subtitle {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 120%;
    margin-top: 20px;
    margin-bottom: 12px;
}


@media only screen and (max-width: 768px) {

    .policies__main {
        padding-top: 66px;
    }

    .policies__main__title {
        font-size: 1.75rem;
        line-height: 35px;
    }

    .policies__main__title__decoration {
        width: 119px;
        height: 10px;
        margin-bottom: 16px;
    }

    .policies__main__text {
        font-size: 0.875rem;
        line-height: 20px;
        margin-bottom: 48px;
    }

    .policies__main__text__subtitle {
        font-size: 1.125rem;
        margin-top: 16px;
    }
}
