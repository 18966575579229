.home-page__main-section {
    background: linear-gradient(306.85deg, rgba(252, 241, 228, 0.75) 27.77%, rgba(253, 248, 241, 0) 100%);
    opacity: 0.9;
}

.home-page__main-section__nav__logo {
    display: flex;
    align-items: center;
}

.home-page__main-section__nav__logo img {
    width: 100%;
}

.home-page__main-section__nav__list {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    gap: 44px;
}

.home-page__main-section__nav__list-item__link {
    font-weight: 600;
    font-size: 1rem;
    line-height: 23px;
    text-decoration: none;
    color: var(--color-black);
}

.home-page__main-section__nav__list-item__link:hover {
    color: var(--color-orange);
}

.home-page__main-section__hero {
    padding-top: 124px;
}

.home-page__main-section__hero__top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-page__main-section__hero__top__title {
    max-width: 1037px;
    padding: 0;
    font-weight: 800;
    font-size: 3.375rem;
    line-height: 68px;
    text-align: center;
    margin: 0 0 32px;
    position: relative;
    text-indent: 32px;
}

.home-page__main-section__hero__top__title__decoration {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -9px;
    display: flex;
}

.home-page__main-section__hero__top__subtitle {
    max-width: 792px;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 150%;
    text-align: center;
    margin-bottom: 10px;
}

.home-page__main-section__hero__top__text {
    max-width: 690px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
    margin-bottom: 24px;
}

.home-page__main-section__hero__bottom {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 100px;
}

.home-page__main-section__hero__bottom__image {
    width: 150%;
    min-width: 600px;
    height: auto;
}


/*About section*/

.home-page__about-section__content {
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-page__about-section__title {
    max-width: 1024px;
    padding: 0;
    font-weight: 800;
    font-size: 2.75rem;
    line-height: 58px;
    text-align: center;
    margin-bottom: 76px;
}

.home-page__about-section__image-and-description {
    width: 100%;
    max-width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.home-page__about-section__image-and-description.with-arrow {
    position: relative;
    padding-right: 80px;
}

.home-page__about-section__image-and-description__curly-arrow {
    width: 142px;
    height: 166px;
    position: absolute;
    top: 0;
    right: 0;
}

.home-page__about-section__family-story__wrapper {
    max-width: 1440px;
    width: 100%;
    padding: 76px 0;
}

.home-page__about-section__image-and-description.family-story__content {
    width: 100%;
    background: #FDF7F1;
    border-radius: 44px;
    height: 420px;
    padding: 0 100px;
    overflow: visible;
    position: relative;
    max-width: unset;
}

.home-page__about-section__family-story__upper-layer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.home-page__about-section__family-story__shadow__wrapper {
    height: 100%;
    width: 100%;
    border-radius: 44px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.home-page__about-section__family-story__shadow__content {
    position: absolute;
    right: 134px;
    width: 443px;
    height: 443px;
    background: radial-gradient(49.58% 49.58% at 50.42% 50.42%, #FFC6A5 0%, #FCF1E4 100%);
    filter: blur(50px);
    border-radius: 12px;
}


.home-page__about-section__image-and-description__image {
    width: 270px;
}

.home-page__about-section__image-and-description__image img {
    width: 100%;
}

.home-page__about-section__image-and-description__text-block {
    width: 100%;
}

.home-page__about-section__image-and-description__text-block.one {
    max-width: 538px;
}

.home-page__about-section__image-and-description__text-block.two {
    max-width: 588px;
}

.home-page__about-section__image-and-description__text-block.three {
    max-width: 564px;
}

.home-page__about-section__image-and-description__text-block__title {
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 58px;
    margin-bottom: 16px;
}

.home-page__about-section__image-and-description__text-block__list {
    list-style-type: none;
}

.home-page__about-section__image-and-description__text-block__list__item {
    font-size: 1.125rem;
    line-height: 26px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
}

.home-page__about-section__image-and-description__text-block__list__item svg {
    width: 24px;
    height: 24px;
}

.home-page__about-section__image-and-description__text-block__title svg {
    margin-bottom: 16px;
    margin-left: 13px;
    width: 35px;
    height: 27px;
}


/*FAQ section*/

.home-page__faq-section {
    background: linear-gradient(306.85deg, rgba(252, 241, 228, 0.75) 27.77%, rgba(253, 248, 241, 0) 100%);
    opacity: 0.9;
    padding-top: 86px;
    padding-bottom: 60px;
}

.home-page__faq-section__title {
    font-weight: 800;
    font-size: 3.375rem;
    line-height: 68px;
    text-align: center;
    margin-bottom: 40px;
}

/*Download section*/

.home-page__download-section {
    padding: 90px 0;
}

.home-page__download-section__content {
    background: #FDF7F1;
    border-radius: 44px;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
}


.home-page__download-section__upper-layer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.home-page__download-section__title {
    font-weight: 800;
    font-size: 3.375rem;
    line-height: 68px;
    text-align: center;
    max-width: 758px;
    margin-bottom: 50px;
}

.home-page__download-section__title svg {
    width: 30px;
    height: 24px;
    margin-bottom: 16px;
    margin-left: 13px;
}

.home-page__download-section__buttons-container {
    display: flex;
    position: relative;
}

.home-page__download-section__buttons-container svg {
    width: 147px;
    height: 149px;
    position: absolute;
    top: -90%;
    left: -35%;
}

.home-page__download-section__shadow {
    position: absolute;
    width: 443px;
    height: 443px;
    right: 0;
    top: 0;
    background: radial-gradient(49.58% 49.58% at 50.42% 50.42%, #FFC6A5 0%, #FCF1E4 100%); /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    filter: blur(50px);
    border-radius: 12px;
}

/*Footer*/

.home-page__footer {
    padding-bottom: 30px;
}

.home-page__footer__content {
    font-weight: 600;
    font-size: 1rem;
    line-height: 23px;
}

.home-page__footer__links-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.home-page__footer__links-section__links-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 70%;
}

.home-page__footer__links-section__links-list__link {
    color: var(--color-black);
    text-decoration: none;
}

.home-page__footer__links-section__links-list__link:hover {
    color: var(--color-orange)
}

.home-page__footer__copyright-section {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 22px;
    color: var(--color-grey);
}


@media only screen and (max-width: 1100px) {

    .home-page__about-section__image-and-description.family-story__content {
        height: 100%;
        padding: 26px;
    }

    .home-page__about-section__family-story__upper-layer .home-page__about-section__image-and-description__image {
        margin-top: -64px;
    }
}


@media only screen and (max-width: 950px) {
    .home-page__footer__links-section {
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {

    /*Main section*/
    .home-page__main-section__nav__logo {
        width: 140px;
        height: 30px;
    }

    .home-page__main-section__nav__list-item__link {
        font-size: 0.875rem;
    }

    .home-page__main-section__hero {
        padding-top: 95px;
    }

    .home-page__main-section__hero__bottom {
        padding: 0;
    }

    .home-page__main-section__hero__top__title {
        font-size: 1.75rem;
        line-height: 35px;
    }

    .home-page__main-section__hero__top__subtitle {
        font-size: 1.125rem;
        line-height: 25px;
    }

    .home-page__main-section__hero__top__text {
        font-size: 0.875rem;
        line-height: 20px;
        margin-bottom: 20px;
    }

    /*About section*/
    .home-page__about-section__content {
        padding-top: 64px;
    }

    .home-page__about-section__title {
        font-size: 1.5rem;
        line-height: 30px;
        margin-bottom: 40px;
    }

    .home-page__about-section__image-and-description {
        flex-direction: column;
        gap: 28px;
    }

    .home-page__about-section__image-and-description__image {
        width: 204px;
    }

    .home-page__about-section__image-and-description__text-block__title {
        font-size: 1.5rem;
        line-height: 120%;
    }

    .home-page__about-section__image-and-description__text-block__list__item {
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .home-page__about-section__image-and-description__curly-arrow {
        width: 90px;
        height: 99px;
        top: -50px;
    }

    .home-page__about-section__image-and-description.with-arrow {
        padding-right: 0;
    }


    .home-page__about-section__family-story__wrapper {
        padding-top: 134px;
    }

    .home-page__about-section__image-and-description.family-story__content {
        padding: 0 16px;
        background: radial-gradient(64.49% 64.49% at 50% 15.65%, #FDDAC3 0%, #FCF6EF 100%);
    }

    .home-page__about-section__family-story__upper-layer {
        flex-direction: column;
    }

    .home-page__about-section__family-story__upper-layer .home-page__about-section__image-and-description__image {
        order: 1;
        margin-top: -64px;
        margin-bottom: 32px;
    }

    .home-page__about-section__family-story__upper-layer .home-page__about-section__image-and-description__text-block {
        order: 2;
    }

    .home-page__about-section__family-story__shadow__content {
        display: none;
    }

    .home-page__about-section__image-and-description__text-block__title svg {
        margin-bottom: 16px;
        margin-left: 2px;
        width: 24px;
        height: 19px;
    }

    /*FAQ section*/
    .home-page__faq-section {
        padding-top: 52px;
        padding-bottom: 32px;
    }

    .home-page__faq-section__title {
        font-size: 1.75rem;
        line-height: 35px;
        margin-bottom: 32px;
    }

    /*Download section*/
    .home-page__download-section {
        padding: 60px 0 48px;
    }

    .home-page__download-section__content {
        padding: 48px 0 32px;
        background: radial-gradient(50% 50% at 50% 50%, #FDDAC3 0%, #FCF6EF 100%);
    }

    .home-page__download-section__title {
        font-size: 1.75rem;
        line-height: 35px;
        margin-bottom: 28px;
    }

    .home-page__download-section__title svg {
        width: 24px;
        height: 19px;
        margin-left: 8px;
    }

    .home-page__download-section .download-buttons {
        flex-direction: column;
    }

    .home-page__download-section__buttons-container svg {
        width: 89px;
        height: 84px;
        top: -20%;
        left: -40%;
    }


    .home-page__download-section__shadow {
        display: none;
    }

    /*Footer*/
    .home-page__footer__links-section,
    .home-page__footer__links-section__links-list {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .home-page__footer__copyright-section {
        margin-top: 20px;
    }

}


@media only screen and (max-width: 330px) {
    .home-page__about-section__image-and-description__text-block__title svg {
        width: 18px;
        height: 15px;
        margin-left: 0;
    }
}


