.accordion__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 10px;
    background-color: var(--color-orange-light);
    border-radius: 40px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.accordion__item__heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion__item__heading__title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 120%;
}

.accordion__item__heading__sign {
    margin-left: 14px;
    font-size: 30px;
}


.accordion__item__heading:hover .accordion__item__heading__sign {
    color: var(--color-orange);
}

.accordion__item__description {
    margin-top: 20px;
    font-size: 1.125rem;
    line-height: 26px;
}

.home-page__faq-section__button {
    background-color: var(--color-orange);
    color: #FFF;
    width: 170px;
    height: 48px;
    margin: 52px auto 0 auto;
}

.home-page__faq-section__button:hover {
    background-color: var(--color-orange-bright);
}

@media only screen and (max-width: 768px) {

    .accordion__item {
        padding: 24px;
    }

    .accordion__item__heading__title {
        font-size: 1.125rem;
    }

    .accordion__item__heading__sign {
        font-size: 26px;
    }

    .accordion__item__description {
        margin-top: 11px;
        font-size: 1rem;
        line-height: 24px;
    }

    .home-page__faq-section__button {
        margin-top: 28px;
        width: 140px;
        height: 40px;
    }

    .accordion__item__heading:hover .accordion__item__heading__sign {
        color: inherit;
    }

}
