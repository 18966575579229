* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;

  --color-black: #050505;
  --color-orange: #D9472A;
  --color-orange-light: #FCF1E4;
  --color-orange-bright: #D7300E;
  --color-grey:  #5E5C5C;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
