@import 'assets/styles/homePage.css';
@import 'assets/styles/Accordion.css';
@import 'assets/styles/policies.css';

.App {
    color: var(--color-black);
}

.header {
    height: 80px;
    width: 100%;
    margin-bottom: 34px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__scrolled {
    background-color: #FFF;
}

.header__content {
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
}

.section-content-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 100px;
}


.color-orange {
    color: var(--color-orange);
}


.download-buttons {
    display: flex;
    align-items: center;
    gap: 24px;
}

.home-page__main-section .download-buttons {
    margin-bottom: 84px;
}

.download-buttons__ios img{
    width: 165px;
    padding: 10px;
}

.download-buttons__google {
    width: 185px;
}

.button {
    border-radius: 10px;
    border: none;
    padding: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {

    .header {
        height: 50px;
    }

    .header__content,
    .section-content-container {
        padding: 0 16px;
    }

    .download-buttons {
        gap: 0;
    }

    .home-page__main-section .download-buttons {
        margin-bottom: 54px;
    }

    .download-buttons__ios img {
        width: 130px;
    }

    .download-buttons__google {
        width: 145px;
    }
}
